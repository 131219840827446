
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getRechargeList, exportRechargeList } from "@/api/request/recharge";

//组件
@Component({
  name: "RechargeRecord",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //统计数据
  private real_user: number = 0;
  private total_user: number = 0;
  private real_money: number = 0;
  private total_money: number = 0;
  private sand_box_user: number = 0;
  private sand_box_money: number = 0;

  //请求数据
  private listQuery: any = {
    //页码数据
    page: 0,
    row: 20,

    //临时数据
    time: [],

    //请求数据
    status: -1,
    end_time: "",
    start_time: "",
    user_id: undefined,
    channel_sn: undefined,
    sn: undefined,

    //是否导出
    excel: false,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //支付状态
  private statusOptions: any[] = [
    { value: -1, label: "全部" },
    { value: 0, label: "等待支付" },
    { value: 1, label: "支付成功" },
    { value: 3, label: "支付失败" },
  ];

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD90()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
    this.listQuery.start_time += "  00:00:00";
    this.listQuery.end_time += "  23:59:59";
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.user_id) this.listQuery.user_id = Number(this.listQuery.user_id);

    //获取数据
    const { data } = await getRechargeList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.real_user = data.recharge_total.real_user;
    this.total_user = data.recharge_total.total_user;
    this.real_money = data.recharge_total.real_money;
    this.total_money = data.recharge_total.total_money;
    this.sand_box_user = data.recharge_total.sand_box_user;
    this.sand_box_money = data.recharge_total.sand_box_money;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理导出
  private async exportList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.user_id) this.listQuery.user_id = Number(this.listQuery.user_id);

    //获取数据
    const { data } = await exportRechargeList(this.listQuery);

    //保存数据
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}充值记录`) : saveAs(data, "充值记录总览");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //清空数据
    this.listQuery.status = -1;
    this.listQuery.sn = undefined;
    this.listQuery.user_id = undefined;
    this.listQuery.channel_sn = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //支付状态处理
  private statusFormat(row: any): string {
    if (row.status === 0) return "等待支付";
    if (row.status === 1) return "支付成功";
    if (row.status === 3) return "支付失败";
    return "";
  }
}
